* {
  font-family: 'Asap', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Sedgwick+Ave+Display&display=swap');

body {
  color: white;
  background: linear-gradient(90deg, rgba(18,18,18,1) 0%, rgba(33,33,33,1) 50%, rgba(18,18,18,1) 100%);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
  margin: 0;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loader {
  height: 70px;
  display: flex;
  align-items: center;
  margin: 3rem;
  margin-top: 5rem;
}

.loader .stroke {
  display: block;
  position: relative;
  background: #f1f1f1;
  height: 100%;
  width: 10px;
  border-radius: 50px;
  margin: 0 5px;
  animation: animate 1.2s linear infinite;
}

.stroke:hover {
  background: green;
}


@keyframes animate {
  50% {
    height: 20%;
  }
  100% {
    height: 100%;
  }
}

.stroke:nth-child(1) {
  animation-delay: 0s;
}
.stroke:nth-child(2) {
  animation-delay: 0.3s;
}
.stroke:nth-child(3) {
  animation-delay: 0.6s;
}
.stroke:nth-child(4) {
  animation-delay: 0.9s;
}
.stroke:nth-child(5) {
  animation-delay: 0.6s;
}
.stroke:nth-child(6) {
  animation-delay: 0.3s;
}
.stroke:nth-child(7) {
  animation-delay: 0s;
}